import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
	Button,
	TextField,
	Dialog,
	Select,
	FormControl,
	InputLabel,
	MenuItem,
	makeStyles,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Grid
} from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import useQueues from "../../hooks/useQueues";
import useWhatsApps from "../../hooks/useWhatsApps";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
	maxWidth: {
		width: "100%",
	},
	dialogPaper: {
		minWidth: '50vw',
		maxWidth: '50vw',
	},
	gridContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
}));

const filterOptions = createFilterOptions({
	trim: true,
});

const CrmModal = ({ modalOpen, onClose, ticketid, ticketWhatsappId }) => {
	const history = useHistory();
	const [selectedCliente, setSelectedCliente] = useState(null);
	const [clientes_options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [funilData, setFunilData] = useState(null);
	const [ticketCrmVinculado, setTicketCrmVinculado] = useState(null);
	const [etapaData, setEtapaData] = useState(null);
	const [statusData, setStatusData] = useState(null);
	const [selectedEtapa, setSelectedEtapa] = useState(null);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [selectedFunil, setSelectedFunil] = useState(null);
	const [selectedTitulo, setSelectedTitulo] = useState("");
	const classes = useStyles();

	const { loadingWhatsapps, whatsApps } = useWhatsApps();

	const { user: loggedInUser } = useContext(AuthContext);

	useEffect(() => {
		const fetchCrmData = async () => {
			try {
				const response = await api.get(`/crm/${ticketid}`);
				setFunilData(response.data.dados_funil);

				if (response.data.ticket_crm_vinculado) {
					setTicketCrmVinculado(response.data.ticket_crm_vinculado.id);
					populaCrmVinculado(response.data.dados_crm);
					setSelectedCliente(response.data.dados_crm.cliente);
				} else {

					var clienteArray = "";
					if (response.data.cliente.id) {
						var clienteArray = Array.isArray(response.data.cliente) ? response.data.cliente[0] : response.data.cliente;
						setSelectedCliente(clienteArray);

					}
					setTicketCrmVinculado(null);
					populaCrmVinculado(null);
				}
			} catch (err) {

			}
		};

		fetchCrmData();
	}, [ticketid]);

	const populaCrmVinculado = async (dados_crm) => {

		if (dados_crm) {

			setSelectedFunil(dados_crm.id_funilcrm);
			setSelectedEtapa(dados_crm.id_etapas);
			setSelectedStatus(dados_crm.id_status);
			setSelectedTitulo(dados_crm.sn_nomenegocio);

			try {
				const response = await api.get(`/funil/${dados_crm.id_funilcrm}`);
				setEtapaData(response.data.etapas);
				setStatusData(response.data.status);
			} catch (err) {

			}

		}
	};

	useEffect(() => {
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchClientes = async () => {
				try {
					const { data } = await api.get("/clientes/", {
						params: { searchParam },
					});

					const clientesArray = Array.isArray(data.clientes) ? data.clientes : [];
					setOptions(clientesArray);
					setLoading(false);
				} catch (err) {
					setLoading(false);
				}
			};

			fetchClientes();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen]);

	const handleClose = () => {
		onClose();
		if (!ticketCrmVinculado) {
			setSearchParam("");
			setSelectedEtapa(null);
			setSelectedStatus(null);
			setSelectedFunil(null);
			setSelectedTitulo("");
		}
	};

	const handleSaveCrm = async (e) => {
		e.preventDefault();
		if (!ticketid) return;

		var crm_whatsapp_vinculo = null;
		var dados_crm = null;

		setLoading(true);
		try {
			let data = {};

			data.ticketid = ticketid;

			if (selectedCliente) {
				data.clienteId = selectedCliente.id;
			} else {
				console.error("selectedCliente está vazio");
				return;
			}

			if (selectedFunil) {
				data.selectedFunil = selectedFunil;
			} else {
				console.error("selectedFunil está vazio");
				return;
			}

			if (selectedEtapa) {
				data.selectedEtapa = selectedEtapa;
			} else {
				console.error("selectedEtapa está vazio");
				return;
			}

			if (selectedStatus) {
				data.selectedStatus = selectedStatus;
			} else {
				console.error("selectedStatus está vazio");
				return;
			}

			if (selectedTitulo) {
				data.selectedTitulo = selectedTitulo;
			} else {
				console.error("selectedTitulo está vazio");
				return;
			}

			const response = await api.post("/crm", data);

			if (response.data.sucesso === true) {

				toast.success(i18n.t("Vínculo salvo com sucesso"));
				setTicketCrmVinculado(response.data.crm_whatsapp_vinculo.id);
				populaCrmVinculado(response.data.dados_crm);
			} else {
				toast.error("Erro ao salvar vínculo");
			}
		} catch (error) {
			toast.error("Erro para vincular o Ticket ao Crm");
		} finally {
			setLoading(false);
			handleClose();
		}
	};


	const handleFunilChange = async (e) => {
		const selectedFunil = e.target.value;
		setSelectedFunil(selectedFunil);
		try {
			const response = await api.get(`/funil/${selectedFunil}`);
			setEtapaData(response.data.etapas);
			setStatusData(response.data.status);
		} catch (err) {
			toastError(err);
		}
	};

	const handleEtapaChange = async (e) => {
		const etapaSelecionada = e.target.value;
		setSelectedEtapa(etapaSelecionada);
	};

	const handleStatusChange = async (e) => {
		const selectedStatus = e.target.value;
		setSelectedStatus(selectedStatus);
	};

	const handleDeleteVinculoCrm = async (ticketCrmVinculado) => {
		setLoading(true);
		try {
			const retorno = await api.delete(`/crm/${ticketCrmVinculado}`);

			if (retorno.data.sucesso === true) {
				toast.success(i18n.t(retorno.data.msg || "Vínculo removido com sucesso"));
			} else {
				return;
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		handleClose();
		setTicketCrmVinculado(null);
		setSearchParam("");
		setSelectedEtapa(null);
		setSelectedStatus(null);
		setSelectedFunil(null);
		setSelectedTitulo("");
	};
	const isDisabled = !!ticketCrmVinculado;

	return (
		<Dialog open={modalOpen} onClose={handleClose} classes={{ paper: classes.dialogPaper }} scroll="paper">
			<form onSubmit={handleSaveCrm}>
				<DialogTitle id="form-dialog-title">
					{i18n.t(!ticketCrmVinculado ? "Novo Card CRM" : "Card Vinculado ao Ticket")}
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={3} style={{ marginBottom: 20 }}>
						<Grid item xs={6}>
							<FormControl variant="outlined" className={classes.maxWidth}>
								<InputLabel>{i18n.t("Funil")}</InputLabel>
								<Select
									value={selectedFunil || ''}
									onChange={handleFunilChange}
									label={i18n.t("Funil")}
									disabled={isDisabled}
								>
									<MenuItem value={''}>&nbsp;</MenuItem>
									{funilData && funilData.map((funil) => (
										<MenuItem key={funil.id} value={funil.id}>{funil.sn_nome}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							<FormControl variant="outlined" className={classes.maxWidth}>
								<InputLabel>{i18n.t("Etapa")}</InputLabel>
								<Select
									value={selectedEtapa || ''}
									onChange={handleEtapaChange}
									label={i18n.t("Etapa")}
									disabled={isDisabled}
								>
									<MenuItem value={''}>&nbsp;</MenuItem>
									{etapaData && etapaData.map((etapa) => (
										<MenuItem key={etapa.id} value={etapa.id} style={{ backgroundColor: etapa.sn_cor }}>{etapa.sn_nome}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					<Grid container spacing={3} style={{ marginBottom: 20 }}>
						<Grid item xs={6}>
							<Autocomplete
								disabled={isDisabled}
								style={{ width: '100%' }}
								getOptionLabel={(option) => `${option.id} - ${option.razao}`}
								onChange={(e, newValue) => {
									setSelectedCliente(newValue);
								}}
								options={clientes_options}
								filterOptions={filterOptions}
								value={selectedCliente || null}
								freeSolo
								autoHighlight
								noOptionsText={i18n.t("transferTicketModal.noOptions")}
								loading={loading}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Cliente"
										variant="outlined"
										autoFocus
										onChange={(e) => setSearchParam(e.target.value)}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{loading ? (
														<CircularProgress color="primary" size={20} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}

									/>
								)}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								name="titulo"
								style={{ width: '100%' }}
								label="Título"
								variant="outlined"
								value={selectedTitulo || ''}
								onChange={(e) => {
									setSelectedTitulo(e.target.value);
								}}
								disabled={isDisabled}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3} className={classes.gridContainer}>
						<Grid item xs={6}>
							<FormControl variant="outlined" className={classes.maxWidth}>
								<InputLabel>{i18n.t("Status")}</InputLabel>
								<Select
									value={selectedStatus || ''}
									onChange={handleStatusChange}
									label={i18n.t("Status")}
									disabled={isDisabled}
								>
									<MenuItem value={''}>&nbsp;</MenuItem>
									{statusData && statusData.map((status) => (
										<MenuItem key={status.id} value={status.id} style={{ backgroundColor: status.sn_cor }}>{status.sn_nome}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("Fechar")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="submit"
						color="primary"
						loading={loading}
						style={{ display: ticketCrmVinculado ? 'none' : 'block' }}
					>
						{i18n.t("Salvar")}
					</ButtonWithSpinner>
					<ButtonWithSpinner
						variant="contained"
						onClick={() => handleDeleteVinculoCrm(ticketCrmVinculado)}
						color="secondary"
						loading={loading}
						style={{ display: ticketCrmVinculado ? 'block' : 'none' }}
					>
						{i18n.t("Excluir")}
					</ButtonWithSpinner>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default CrmModal;